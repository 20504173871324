import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/ourservices/_services.scss';

class DentalImplants extends Component {
  render() {
    const { ourServiceData } = this.props;
    return (
      <>
        <section className="services-content h-100">
          <Container fluid className="services-containter">
            <Row>
              <Col md={12} className="text-left services-servicesTitle mt-3">
                <h2 className="text-center">
                  {ourServiceData[0].ourServices.dentalImplants.title}
                </h2>
                <p className="mt-4">
                  {ourServiceData[0].ourServices.dentalImplants.discriptionOne}
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {ourServiceData[0].ourServices.dentalImplants.subtitleOne}
                </h3>
                <p>
                  <u>
                    {ourServiceData[0].ourServices.dentalImplants.underlineOne}
                  </u>{' '}
                  {ourServiceData[0].ourServices.dentalImplants.discriptionTwo}
                </p>
                <p>
                  <u>
                    {ourServiceData[0].ourServices.dentalImplants.underlineTwo}
                  </u>{' '}
                  {
                    ourServiceData[0].ourServices.dentalImplants
                      .discriptionThree
                  }
                </p>
                <p>
                  <u>
                    {
                      ourServiceData[0].ourServices.dentalImplants
                        .underlineThree
                    }
                  </u>{' '}
                  {ourServiceData[0].ourServices.dentalImplants.discriptionFour}
                </p>
                <p>
                  <u>
                    {ourServiceData[0].ourServices.dentalImplants.underlineFour}
                  </u>{' '}
                  {ourServiceData[0].ourServices.dentalImplants.discriptionFive}
                </p>
                <p>
                  {ourServiceData[0].ourServices.dentalImplants.discriptionSix}
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {ourServiceData[0].ourServices.dentalImplants.subtitleTwo}
                </h3>
                <p>
                  {
                    ourServiceData[0].ourServices.dentalImplants
                      .discriptionSeven
                  }
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {ourServiceData[0].ourServices.dentalImplants.subtitleThree}
                </h3>
                <p>
                  {
                    ourServiceData[0].ourServices.dentalImplants
                      .discriptionEight
                  }
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query DentalImplantsQuery {
        allOurservicesJson {
          nodes {
            ourServices {
              dentalImplants {
                title
                discriptionOne
                subtitleOne
                underlineOne
                discriptionTwo
                underlineTwo
                discriptionThree
                underlineThree
                discriptionFour
                underlineFour
                discriptionFive
                underlineFive
                discriptionSix
                subtitleTwo
                discriptionSeven
                subtitleThree
                discriptionEight
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <DentalImplants ourServiceData={data.allOurservicesJson.nodes} />
    )}
  />
);

DentalImplants.propTypes = {
  ourServiceData: PropTypes.array.isRequired,
};
