import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/ourservices/_services.scss';

class CrownLengthening extends Component {
  render() {
    const { ourServiceData, imageOne, imageTwo } = this.props;
    return (
      <>
        <section className="services-content h-100">
          <Container fluid className="services-containter">
            <Row>
              <Col md={12} className="text-left services-servicesTitle mt-3">
                <h2 className="text-center">
                  {ourServiceData[0].ourServices.crownLengthening.title}
                </h2>
                <p className="mt-4">
                  {
                    ourServiceData[0].ourServices.crownLengthening
                      .discriptionOne
                  }
                </p>
                <Row className="pb-1 text-center">
                  <Col md={6}>
                    <Img
                      fluid={imageOne.childImageSharp.fluid}
                      style={{ width: '18rem', height: '10rem' }}
                      alt="Slide"
                      imgStyle={{ objectFit: 'contain' }}
                      className="mx-auto d-block mb-2"
                    />
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.crownLengthening
                            .imageOneText
                        }
                      </b>
                    </p>
                  </Col>
                  <Col md={6}>
                    <Img
                      fluid={imageTwo.childImageSharp.fluid}
                      style={{ width: '18rem', height: '10rem' }}
                      alt="Slide"
                      imgStyle={{ objectFit: 'contain' }}
                      className="mx-auto d-block mb-2"
                    />
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.crownLengthening
                            .imageTwoText
                        }
                      </b>
                    </p>
                  </Col>
                </Row>
                <p>
                  {
                    ourServiceData[0].ourServices.crownLengthening
                      .discriptionTwo
                  }
                </p>
                <p>
                  {
                    ourServiceData[0].ourServices.crownLengthening
                      .discriptionThree
                  }
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query CrownLengtheningQuery {
        allOurservicesJson {
          nodes {
            ourServices {
              crownLengthening {
                title
                discriptionOne
                imageOneText
                imageTwoText
                discriptionTwo
                discriptionThree
              }
            }
          }
        }

        imageOne: file(
          relativePath: { eq: "services/crownlengthening/imageOne.png" }
        ) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        imageTwo: file(
          relativePath: { eq: "services/crownlengthening/imageTwo.png" }
        ) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <CrownLengthening
        ourServiceData={data.allOurservicesJson.nodes}
        imageOne={data.imageOne}
        imageTwo={data.imageTwo}
      />
    )}
  />
);

CrownLengthening.propTypes = {
  ourServiceData: PropTypes.array.isRequired,
  imageOne: PropTypes.object.isRequired,
  imageTwo: PropTypes.object.isRequired,
};
