import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/ourservices/_services.scss';

class LaserTherapy extends Component {
  render() {
    const { ourServiceData } = this.props;
    return (
      <>
        <section className="services-content h-100">
          <Container fluid className="services-containter">
            <Row>
              <Col md={12} className="text-left services-servicesTitle mt-3">
                <h2 className="text-center">
                  {ourServiceData[0].ourServices.laserTherapy.title}
                </h2>
                <p className="mt-4">
                  {ourServiceData[0].ourServices.laserTherapy.discriptionOne}
                </p>
                <p>
                  {ourServiceData[0].ourServices.laserTherapy.discriptionTwo}
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {ourServiceData[0].ourServices.laserTherapy.subtitleOne}
                </h3>
                <ul>
                  <li>
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.laserTherapy
                            .bulletOneBold
                        }
                      </b>
                      {ourServiceData[0].ourServices.laserTherapy.bulletOne}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.laserTherapy
                            .bulletTwoBold
                        }
                      </b>
                      {ourServiceData[0].ourServices.laserTherapy.bulletTwo}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.laserTherapy
                            .bulletThreeBold
                        }
                      </b>
                      {ourServiceData[0].ourServices.laserTherapy.bulletThree}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.laserTherapy
                            .bulletFourBold
                        }
                      </b>
                      {ourServiceData[0].ourServices.laserTherapy.bulletFour}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.laserTherapy
                            .bulletFiveBold
                        }
                      </b>
                      {ourServiceData[0].ourServices.laserTherapy.bulletFive}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.laserTherapy
                            .bulletSixBold
                        }
                      </b>
                      {ourServiceData[0].ourServices.laserTherapy.bulletSix}
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        {
                          ourServiceData[0].ourServices.laserTherapy
                            .bulletSevenBold
                        }
                      </b>
                      {ourServiceData[0].ourServices.laserTherapy.bulletSeven}
                    </p>
                  </li>
                </ul>
                <Row className="pb-5">
                  <Col
                    md={12}
                    className="mt-4 embed-responsive embed-responsive-21by9"
                  >
                    <iframe
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/pkT918S93W0?rel=0&modestbranding=1&controls=0"
                      title="Laser Therapy Video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                    {/* <Img
                      fluid={imageOne.childImageSharp.fluid}
                      style={{ width: '20rem', height: '15rem' }}
                      alt="Slide"
                      imgStyle={{ objectFit: 'contain' }}
                      className="mx-auto d-block"
                    />
                  </Col>
                  <Col md={6} className="mt-4">
                    <Img
                      fluid={imageTwo.childImageSharp.fluid}
                      style={{ width: '20rem', height: '15rem' }}
                      alt="Slide"
                      imgStyle={{ objectFit: 'contain' }}
                      className="mx-auto d-block"
                    /> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query LaserTherapyQuery {
        allOurservicesJson {
          nodes {
            ourServices {
              laserTherapy {
                title
                discriptionOne
                discriptionTwo
                subtitleOne
                bulletOneBold
                bulletOne
                bulletTwoBold
                bulletTwo
                bulletThreeBold
                bulletThree
                bulletFourBold
                bulletFour
                bulletFiveBold
                bulletFive
                bulletSixBold
                bulletSix
                bulletSevenBold
                bulletSeven
              }
            }
          }
        }

        imageOne: file(
          relativePath: { eq: "services/lasertherapy/imageOne.png" }
        ) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        imageTwo: file(
          relativePath: { eq: "services/lasertherapy/imageTwo.png" }
        ) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <LaserTherapy ourServiceData={data.allOurservicesJson.nodes} />
    )}
  />
);

LaserTherapy.propTypes = {
  ourServiceData: PropTypes.array.isRequired,
};
