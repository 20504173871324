import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/ourservices/_services.scss';

class GumGrafting extends Component {
  render() {
    const { ourServiceData } = this.props;
    return (
      <>
        <section className="services-content h-100">
          <Container fluid className="services-containter">
            <Row>
              <Col md={12} className="text-left services-servicesTitle mt-3">
                <h2 className="text-center">
                  {ourServiceData[0].ourServices.gumGrafting.title}
                </h2>
                <p className="mt-4">
                  {ourServiceData[0].ourServices.gumGrafting.discriptionOne}
                </p>
                <p>
                  {ourServiceData[0].ourServices.gumGrafting.discriptionTwo}
                </p>
                <p>
                  {ourServiceData[0].ourServices.gumGrafting.discriptionThree}
                </p>
                <p>
                  {ourServiceData[0].ourServices.gumGrafting.discriptionFour}
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {ourServiceData[0].ourServices.gumGrafting.subtitleOne}
                </h3>
                <p>
                  {ourServiceData[0].ourServices.gumGrafting.discriptionFive}
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query GumGraftingQuery {
        allOurservicesJson {
          nodes {
            ourServices {
              gumGrafting {
                title
                discriptionOne
                discriptionTwo
                discriptionThree
                discriptionFour
                subtitleOne
                discriptionFive
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <GumGrafting ourServiceData={data.allOurservicesJson.nodes} />
    )}
  />
);

GumGrafting.propTypes = {
  ourServiceData: PropTypes.array.isRequired,
};
