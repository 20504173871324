import React from 'react';
import Layout from '../layouts/layout';
import SEO from '../components/seo';
import '../scss/_index.scss';
import OurServices from '../components/OurServices/OurServices';

const OurServicesPage = () => (
  <Layout>
    <SEO title="Meet The Team" />
    <OurServices />
  </Layout>
);

export default OurServicesPage;
