import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/ourservices/_services.scss';

class ToothExtraction extends Component {
  render() {
    const { ourServiceData } = this.props;
    return (
      <>
        <section className="services-content h-100">
          <Container fluid className="services-containter">
            <Row>
              <Col md={12} className="text-left services-servicesTitle mt-3">
                <h2 className="text-center">
                  {ourServiceData[0].ourServices.toothExtraction.title}
                </h2>
                <p className="mt-4">
                  {ourServiceData[0].ourServices.toothExtraction.discriptionOne}
                </p>
                <p>
                  {ourServiceData[0].ourServices.toothExtraction.discriptionTwo}
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query ToothExtractionQuery {
        allOurservicesJson {
          nodes {
            ourServices {
              toothExtraction {
                title
                discriptionOne
                discriptionTwo
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <ToothExtraction ourServiceData={data.allOurservicesJson.nodes} />
    )}
  />
);

ToothExtraction.propTypes = {
  ourServiceData: PropTypes.array.isRequired,
};
