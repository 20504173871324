import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Nav, NavDropdown, Row, Tab } from 'react-bootstrap';
import '../../scss/ourservices/_ourServices.scss';
// import AdvancedPeriodontitis from './AdvancedPeriodontitis';
import AllOnFour from './AllOnFour';
import BoneGrafting from './BoneGrafting';
import CrownLengthening from './CrownLengthening';
import DentalImplants from './DentalImplants';
import Frenectomy from './Frenectomy';
import GumDisease from './GumDisease';
import GumGrafting from './GumGrafting';
import LaserTherapy from './LaserTherapy';
import PeriodontalSurgery from './PeriodontalSurgery';
import PlateletRichFibrin from './PlateletRichFibrin';
import SedationDentistry from './SedationDentistry';
import SinusLift from './SinusLift';
import ToothExtraction from './ToothExtraction';

class OurServices extends Component {
  render() {
    const { ourServiceData } = this.props;
    return (
      <>
        <section className="ourService-title h-100">
          <Container fluid className="ourService-containter">
            <Row>
              <Col md={12} className="text-center ourService-sectionTitle">
                <h1>Our Services</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="ourService-content h-100">
          <Container fluid className="ourService-containter my-5">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="laserTherapy"
            >
              <Row>
                <Col md={3} className="mt-2">
                  <Nav variant="pills" className="flex-column">
                    <div className="d-none d-md-table">
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="laserTherapy">
                          {ourServiceData[0].ourServices.laserTherapy.menuTitle}
                        </Nav.Link>
                        <Nav.Link eventKey="gumDisease">
                          {
                            ourServiceData[0].ourServices.gumDiseaseTreatment
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="gumGrafting">
                          {ourServiceData[0].ourServices.gumGrafting.title}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="toothExtraction">
                          {ourServiceData[0].ourServices.toothExtraction.title}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="boneGrafting">
                          {ourServiceData[0].ourServices.boneGrafting.title}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="dentalImplants">
                          {ourServiceData[0].ourServices.dentalImplants.title}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="sinusLift">
                          {ourServiceData[0].ourServices.sinusLift.title}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="allOnFour">
                          {ourServiceData[0].ourServices.allOnFour.title}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="periodontalSurgery">
                          {
                            ourServiceData[0].ourServices.periodontalSurgery
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="advancedPeriodontitis">
                          {
                            ourServiceData[0].ourServices.advancedPeriodontitis
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="crownLengthening">
                          {ourServiceData[0].ourServices.crownLengthening.title}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="frenectomy">
                          {ourServiceData[0].ourServices.frenectomy.title}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="plateletRichFibrin">
                          {
                            ourServiceData[0].ourServices.plateletRichFibrin
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ourService-nav text-center">
                        <Nav.Link eventKey="sedationDentistry">
                          {
                            ourServiceData[0].ourServices.sedationDentistry
                              .title
                          }
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                    <div className="d-block d-md-none">
                      <NavDropdown
                        title="Services"
                        className="ourService-dropdown w-100"
                      >
                        <NavDropdown.Item eventKey="laserTherapy">
                          {ourServiceData[0].ourServices.laserTherapy.menuTitle}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="gumDisease">
                          {
                            ourServiceData[0].ourServices.gumDiseaseTreatment
                              .title
                          }
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="gumGrafting">
                          {ourServiceData[0].ourServices.gumGrafting.title}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="toothExtraction">
                          {ourServiceData[0].ourServices.toothExtraction.title}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="boneGrafting">
                          {ourServiceData[0].ourServices.boneGrafting.title}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="dentalImplants">
                          {ourServiceData[0].ourServices.dentalImplants.title}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="sinusLift">
                          {ourServiceData[0].ourServices.sinusLift.title}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="allOnFour">
                          {ourServiceData[0].ourServices.allOnFour.title}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="periodontalSurgery">
                          {
                            ourServiceData[0].ourServices.periodontalSurgery
                              .title
                          }
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item eventKey="advancedPeriodontitis">
                          {
                            ourServiceData[0].ourServices.advancedPeriodontitis
                              .title
                          }
                        </NavDropdown.Item> */}
                        <NavDropdown.Item eventKey="crownLengthening">
                          {ourServiceData[0].ourServices.crownLengthening.title}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="frenectomy">
                          {ourServiceData[0].ourServices.frenectomy.title}
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="plateletRichFibrin">
                          {
                            ourServiceData[0].ourServices.plateletRichFibrin
                              .title
                          }
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="sedationDentistry">
                          {
                            ourServiceData[0].ourServices.sedationDentistry
                              .title
                          }
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </Nav>
                </Col>
                <Col md={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="laserTherapy">
                      <LaserTherapy />
                    </Tab.Pane>
                    <Tab.Pane eventKey="gumDisease">
                      <GumDisease />
                    </Tab.Pane>
                    <Tab.Pane eventKey="gumGrafting">
                      <GumGrafting />
                    </Tab.Pane>
                    <Tab.Pane eventKey="toothExtraction">
                      <ToothExtraction />
                    </Tab.Pane>
                    <Tab.Pane eventKey="boneGrafting">
                      <BoneGrafting />
                    </Tab.Pane>
                    <Tab.Pane eventKey="dentalImplants">
                      <DentalImplants />
                    </Tab.Pane>
                    <Tab.Pane eventKey="sinusLift">
                      <SinusLift />
                    </Tab.Pane>
                    <Tab.Pane eventKey="allOnFour">
                      <AllOnFour />
                    </Tab.Pane>
                    <Tab.Pane eventKey="periodontalSurgery">
                      <PeriodontalSurgery />
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="advancedPeriodontitis">
                      <AdvancedPeriodontitis />
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="crownLengthening">
                      <CrownLengthening />
                    </Tab.Pane>
                    <Tab.Pane eventKey="frenectomy">
                      <Frenectomy />
                    </Tab.Pane>
                    <Tab.Pane eventKey="plateletRichFibrin">
                      <PlateletRichFibrin />
                    </Tab.Pane>
                    <Tab.Pane eventKey="sedationDentistry">
                      <SedationDentistry />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query OurServicesQuery {
        allOurservicesJson {
          nodes {
            ourServices {
              laserTherapy {
                menuTitle
              }
              gumDiseaseTreatment {
                title
              }
              gumGrafting {
                title
              }
              toothExtraction {
                title
              }
              boneGrafting {
                title
              }
              dentalImplants {
                title
              }
              sinusLift {
                title
              }
              allOnFour {
                title
              }
              periodontalSurgery {
                title
              }
              advancedPeriodontitis {
                title
              }
              crownLengthening {
                title
              }
              frenectomy {
                title
              }
              plateletRichFibrin {
                title
              }
              sedationDentistry {
                title
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <OurServices ourServiceData={data.allOurservicesJson.nodes} />
    )}
  />
);

OurServices.propTypes = {
  ourServiceData: PropTypes.array.isRequired,
};
