import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/ourservices/_services.scss';

class AllOnFour extends Component {
  render() {
    const { ourServiceData, imageOne, imageTwo, imageThree } = this.props;
    return (
      <>
        <section className="services-content h-100">
          <Container fluid className="services-containter">
            <Row>
              <Col md={12} className="text-left services-servicesTitle mt-3">
                <h2 className="text-center">
                  {ourServiceData[0].ourServices.allOnFour.title}
                </h2>
                <p className="mt-4">
                  {ourServiceData[0].ourServices.allOnFour.discriptionOne}
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {ourServiceData[0].ourServices.allOnFour.subtitleOne}
                </h3>
                <p>{ourServiceData[0].ourServices.allOnFour.discriptionTwo}</p>
                <p>
                  {ourServiceData[0].ourServices.allOnFour.discriptionThree}
                </p>
                <p>{ourServiceData[0].ourServices.allOnFour.discriptionFour}</p>
                <h3 className="services-servicesSubtitle py-2">
                  {ourServiceData[0].ourServices.allOnFour.subtitleTwo}
                </h3>
                <p>{ourServiceData[0].ourServices.allOnFour.discriptionFive}</p>
                <p>{ourServiceData[0].ourServices.allOnFour.discriptionSix}</p>
                <p>
                  {ourServiceData[0].ourServices.allOnFour.discriptionSeven}
                </p>
                <p>
                  {ourServiceData[0].ourServices.allOnFour.discriptionEight}
                </p>
                <p>{ourServiceData[0].ourServices.allOnFour.discriptionNine}</p>
                <p>{ourServiceData[0].ourServices.allOnFour.discriptionTen}</p>

                <Row className="pb-5">
                  <Col md={4}>
                    <Img
                      fluid={imageOne.childImageSharp.fluid}
                      style={{ height: '10rem' }}
                      alt="Slide"
                      imgStyle={{ objectFit: 'contain' }}
                      className="mx-auto d-block mb-2"
                    />
                  </Col>
                  <Col md={4}>
                    <Img
                      fluid={imageTwo.childImageSharp.fluid}
                      style={{ height: '10rem' }}
                      alt="Slide"
                      imgStyle={{ objectFit: 'contain' }}
                      className="mx-auto d-block mb-2"
                    />
                  </Col>
                  <Col md={4}>
                    <Img
                      fluid={imageThree.childImageSharp.fluid}
                      style={{ height: '10rem' }}
                      alt="Slide"
                      imgStyle={{ objectFit: 'contain' }}
                      className="mx-auto d-block mb-2"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query AllOnFourQuery {
        allOurservicesJson {
          nodes {
            ourServices {
              allOnFour {
                title
                discriptionOne
                subtitleOne
                discriptionTwo
                discriptionThree
                discriptionFour
                subtitleTwo
                discriptionFive
                discriptionSix
                discriptionSeven
                discriptionEight
                discriptionNine
                discriptionTen
              }
            }
          }
        }

        imageOne: file(
          relativePath: { eq: "services/allonfour/imageOne.png" }
        ) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        imageTwo: file(
          relativePath: { eq: "services/allonfour/imageTwo.png" }
        ) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }

        imageThree: file(
          relativePath: { eq: "services/allonfour/imageThree.png" }
        ) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <AllOnFour
        ourServiceData={data.allOurservicesJson.nodes}
        imageOne={data.imageOne}
        imageTwo={data.imageTwo}
        imageThree={data.imageThree}
      />
    )}
  />
);

AllOnFour.propTypes = {
  ourServiceData: PropTypes.array.isRequired,
  imageOne: PropTypes.object.isRequired,
  imageTwo: PropTypes.object.isRequired,
  imageThree: PropTypes.object.isRequired,
};
