import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/ourservices/_services.scss';

class GumDisease extends Component {
  render() {
    const { ourServiceData } = this.props;
    return (
      <>
        <section className="services-content h-100">
          <Container fluid className="services-containter">
            <Row>
              <Col md={12} className="text-left services-servicesTitle mt-3">
                <h2 className="text-center">
                  {ourServiceData[0].ourServices.gumDiseaseTreatment.title}
                </h2>
                <p className="mt-4">
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .discriptionOne
                  }
                </p>
                <p>
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .discriptionTwo
                  }
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .subtitleOne
                  }
                </h3>
                <p>
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .discriptionThree
                  }
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .subtitleTwo
                  }
                </h3>
                <p>
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .discriptionFour
                  }
                </p>
                <h3 className="services-servicesSubtitle py-2">
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .subtitleThree
                  }
                </h3>
                <p>
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .discriptionFive
                  }
                </p>
                <p>
                  {
                    ourServiceData[0].ourServices.gumDiseaseTreatment
                      .discriptionSix
                  }
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query GumDiseaseQuery {
        allOurservicesJson {
          nodes {
            ourServices {
              gumDiseaseTreatment {
                title
                discriptionOne
                discriptionTwo
                subtitleOne
                discriptionThree
                subtitleTwo
                discriptionFour
                subtitleThree
                discriptionFive
                discriptionSix
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <GumDisease ourServiceData={data.allOurservicesJson.nodes} />
    )}
  />
);

GumDisease.propTypes = {
  ourServiceData: PropTypes.array.isRequired,
};
